













import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/new-store-bulk-order/new-store-bulk-order-arrival/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import {
  ColumnLabel } from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
} from '@/types/generated/graphql'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'

export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPNewStoreBulkOrderArrival,
      templateFile: TemplateFile.UiPNewStoreBulkOrderArrival_01,
      outputUseList: [
      ],
      totalCount: null,
      exportSpinner: false,
      exportCondition: false
    }
  },
  methods: {
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      await this.helper.export({
        query,
        jmespathQuery:
          'data.vUiPNewStoreBulkOrderArrivals.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.new_store_bulk_order_arrival,
        columnLabelMapping: this._create_column_label_mapping(),
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const orderBy = this._createOrderByStatement()
      const where: Where[] = []
      const rowQuery = `
        query c {
          vUiPNewStoreBulkOrderArrivals(
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                inactiveFlg
                vendorCd
                vendorCdName
                requiredDays 
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where,
      })
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'vendorCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'vendorCd',
          to: '',
        },
        {
          from: 'vendorCdName',
          to: '',
        },
        {
          from: 'requiredDays',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
